import axios from 'axios';

async function getNearByPackages(
  fromLat,
  fromLng,
  toLat,
  toLng,
  radius,
  travel,
) {
  const packages = [];
  const params = [];
  if (fromLat && fromLng) {
    params.push(`from[lat]=${fromLat}&from[long]=${fromLng}`);
  }
  if (toLat && toLng) params.push(`to[lat]=${toLat}&to[long]=${toLng}`);
  if (radius) params.push(`distance=${radius}`);
  if (travel !== undefined) params.push(`travel=${travel}`);
  params.push('status=created');
  const query = params.length ? `?${params.join('&')}` : '';
  try {
    const { data } = await axios.get(`${process.env.REACT_APP_PACKAGE_API_BASE_URL}v1/packages/nearbies${query}`);
    data.forEach((pkg) => {
      const latitude = pkg?.from?.coordinates[1] ?? null;
      const longitude = pkg?.from.coordinates[0] ?? null;
      if (latitude && longitude) {
        packages.push({
          ...pkg,
          latitude,
          longitude,
        });
      }
    });
  } catch (error) {
    console.error(error);
  }

  return packages;
}


export {
  getNearByPackages
};
