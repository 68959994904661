import React from 'react';
import { createRoot } from 'react-dom/client';
import './index.css';
import App from './App';
import * as serviceWorker from './serviceWorker';
import i18next from 'i18next';
import common_ar from './translations/ar/common.json';
import common_fr from './translations/fr/common.json';
import LanguageDetector from 'i18next-browser-languagedetector';
import { I18nextProvider } from 'react-i18next';

i18next.use(LanguageDetector).init({
  interpolation: { escapeValue: false }, // React already does escaping
  fallbackLng: 'fr',
  load: 'languageOnly',
  supportedLngs: ['en', 'fr', 'ar'],
  resources: {
    fr: {
      common: common_fr,
    },
    ar: {
      common: common_ar,
    },
  },
});

const root = createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <I18nextProvider i18n={i18next}>
      <App />
    </I18nextProvider>
  </React.StrictMode>
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
