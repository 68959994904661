import * as React from 'react';
import { Icon, Button, Menu, MenuItem, Container, Typography, IconButton, Toolbar, Box, AppBar } from '@mui/material';
import { Menu as MenuIcon, KeyboardArrowDown as KeyboardArrowDownIcon } from '@mui/icons-material';
import { useTranslation } from 'react-i18next';
import './header1.scss';
import tn from '../../assets/images/tn1x1.svg';
import fr from '../../assets/images/fr1x1.svg';
import logo from '../../assets/images/logo.png';
// import { Link } from 'react-router-dom';

export default function Header() {
  const { t, i18n } = useTranslation('common');
  const [anchorElNav, setAnchorElNav] = React.useState(null);
  const [anchorElLng, setAnchorElLng] = React.useState(null);

  const handleOpenNavMenu = event => {
    setAnchorElNav(event.currentTarget);
  };

  const handleCloseNavMenu = () => {
    setAnchorElNav(null);
  };

  const changeLanguage = event => {
    i18n.changeLanguage(event.currentTarget.getAttribute('value'));
    document.body.dir = i18n.dir();
    setAnchorElLng(null);
  };

  const handleOpenLngMenu = event => {
    setAnchorElLng(event.currentTarget);
  };

  const handleCloseLngMenu = () => {
    setAnchorElLng(null);
  };

  return (
    <AppBar id="header" position="fixed" sx={{ bgcolor: 'white' }}>
      <Container maxWidth="xl">
        <Toolbar disableGutters>
          <IconButton size="small" href="/" sx={{ display: { xs: 'none', md: 'flex' }, mr: 1 }}>
            <Box
              component="img"
              sx={{
                height: 50,
                width: 50,
                maxHeight: { xs: 25, md: 167 },
              }}
              alt="Solution mobile pour le transport de colis entre particuliers en Tunisie et France"
              src={logo}
            />
          </IconButton>
          <Box sx={{ flexGrow: 1, display: { xs: 'flex', md: 'none' } }}>
            <IconButton
              size="large"
              aria-controls="menu-appbar"
              id="menu-appbar"
              aria-haspopup="true"
              onClick={handleOpenNavMenu}
              color="primary"
            >
              <MenuIcon />
            </IconButton>
            <Menu
              id="menu-appbar"
              anchorEl={anchorElNav}
              anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'left',
              }}
              keepMounted
              transformOrigin={{
                vertical: 'top',
                horizontal: 'left',
              }}
              open={Boolean(anchorElNav)}
              onClose={handleCloseNavMenu}
              sx={{
                display: { xs: 'block', md: 'none' },
              }}
            >
              <MenuItem key="feature" onClick={handleCloseNavMenu}>
                <Button href="/#features" textalign="center">
                  {t('header.feature')}
                </Button>
              </MenuItem>
              <MenuItem key="gallery" onClick={handleCloseNavMenu}>
                <Button href="/#gallery" textalign="center">
                  {t('header.gallery')}
                </Button>
              </MenuItem>
              <MenuItem key="contact" onClick={handleCloseNavMenu}>
                <Button href="/#contact" textalign="center">
                  {t('header.contact')}
                </Button>
              </MenuItem>
            </Menu>
          </Box>
          <Typography
            noWrap
            component="a"
            href=""
            sx={{
              mr: 2,
              display: { xs: 'flex', md: 'none' },
              flexGrow: 1,
              letterSpacing: '.3rem',
              color: 'inherit',
            }}
          >
            <IconButton size="small" href="/" sx={{ display: { xs: 'flex', md: 'none' }, mr: 1 }}>
              <Box
                component="img"
                sx={{
                  maxHeight: { xs: 40, md: 50 },
                }}
                alt="Solution mobile pour le transport de colis entre particuliers en Tunisie et France"
                src={logo}
              />
            </IconButton>
          </Typography>
          <Box sx={{ flexGrow: 1, display: { xs: 'none', md: 'flex' } }}>
            <MenuItem key="feature" onClick={handleCloseNavMenu}>
              <Button style={{ backgroundColor: 'transparent' }} href="/#features">
                {t('header.feature')}
              </Button>
            </MenuItem>
            <MenuItem key="gallery" onClick={handleCloseNavMenu}>
              <Button style={{ backgroundColor: 'transparent' }} href="/#gallery">
                {t('header.gallery')}
              </Button>
            </MenuItem>
            <MenuItem key="contact" onClick={handleCloseNavMenu}>
              <Button style={{ backgroundColor: 'transparent' }} href="/#contact">
                {t('header.contact')}
              </Button>
            </MenuItem>
          </Box>
          <Box sx={{ flexGrow: 0 }}>
            <Button
              size="large"
              style={{ backgroundColor: 'transparent' }}
              variant="outline"
              onClick={handleOpenLngMenu}
              endIcon={<KeyboardArrowDownIcon color="primary" sx={{ mr: 2 }} />}
            >
              <Box
                component="img"
                sx={{
                  height: 20,
                  width: 20,
                  maxHeight: { xs: 40, md: 40 },
                }}
                alt="Choisir la langue"
                src={i18n.languages[0] === 'fr' ? fr : tn}
              />
            </Button>
            <Menu open={Boolean(anchorElLng)} anchorEl={anchorElLng} onClose={handleCloseLngMenu} aria-haspopup="true" disableElevation>
              <MenuItem onClick={e => changeLanguage(e)} value={'ar'} selected={i18n.languages[0] === 'ar'}>
                <Icon className="flag-icon">
                  <img src={tn} height="25" width="25" alt="tunisian" />
                </Icon>
              </MenuItem>
              <MenuItem onClick={e => changeLanguage(e)} value={'fr'} selected={i18n.languages[0] === 'fr'}>
                <Icon>
                  <img src={fr} height="25" width="25" alt="french" />
                </Icon>
              </MenuItem>
            </Menu>
          </Box>
        </Toolbar>
      </Container>
    </AppBar>
  );
}
