const travelByMarker = (travelBy) => {
  switch (travelBy) {
    case 'flight':
      return require('../assets/images/marker_flight.png');
    case 'car':
      return require('../assets/images/marker_car.png');
    case 'rental_car':
      return require('../assets/images/marker_rental_car.png');
    case 'bus':
      return require('../assets/images/marker_bus.png');
    case 'motorcycle':
      return require('../assets/images/marker_motorcycle.png');
    case 'bicycle':
      return require('../assets/images/marker_bike.png');
    case 'truck':
      return require('../assets/images/marker_truck.png');
    case 'train':
      return require('../assets/images/marker_train.png');
    case 'taxi':
      return require('../assets/images/marker_taxi.png');
    default:
      return require('../assets/images/marker_car.png');
  }
};

export {
  travelByMarker,
};

