import React from 'react';
import { useTranslation } from 'react-i18next';
import './presentation.scss';
import AppButton from '../../components/AppButton/app-button';
import presentationWEBP from '../../assets/images/presentation.webp';
import presentationAVIF from '../../assets/images/presentation.avif';
import presentationPNG from '../../assets/images/presentation.png';

export default function Presentation() {
  const { t } = useTranslation('common');
  return (
    <section id="presentation" className="d-flex align-items-center">
      <div className="container">
        <div className="row">
          <div
            className="col-lg-6 d-lg-flex flex-lg-column justify-content-center align-items-stretch pt-5 pt-lg-0 order-2 order-lg-1"
            data-aos="fade-up"
          >
            <div>
              <h1>{t('presentation.title')}</h1>
              <h2>{t('presentation.intro')}</h2>
              <AppButton />
            </div>
          </div>
          <div className="col-lg-6 d-lg-flex flex-lg-column align-items-stretch order-1 order-lg-2 presentation-img" data-aos="fade-up">
            <picture>
              <source
                className="img-fluid"
                type="image/webp"
                srcSet={`${presentationWEBP} 300w, ${presentationWEBP} 768w, ${presentationWEBP} 1280w`}
                sizes="(max-width: 300px) 300px, (max-width: 768px) 768px, 1280px"
              />
              <source
                className="img-fluid"
                type="image/avif"
                srcSet={`${presentationAVIF} 300w, ${presentationAVIF} 768w, ${presentationAVIF} 1280w`}
                sizes="(max-width: 300px) 300px, (max-width: 768px) 768px, 1280px"
              />
              <img className="img-fluid" src={presentationPNG} alt="Transport entre particuliers" width="100%" height="auto" />
            </picture>
          </div>
        </div>
      </div>
    </section>
  );
}
